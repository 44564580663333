@import "../utils/mixins";
@import "../utils/font-size";
@import "../utils/variables";

body {
  padding: 0;
  margin: 0;
  font-family: "Roboto", sans-serif;
  height: var(--app-height);
  background-color: $bg-color;
  color: #000000;
}

a {
  transition: all 0.15s ease-in-out;
  text-decoration: none !important;
  cursor: pointer;
  color: $link-color !important;
  &:not(.no-hover-color):hover {
    text-decoration: none;
    color: $link-hover-color !important;
  }
}
p {
  font-weight: 300;
  //line-height: 2.6rem;
  margin-bottom: 0;
}

header#header  {
  //padding: 0 50px;
  min-height: 75px;
  top: 0;
}

// legend {
//   float: inherit;
// }

.logo {
  // min-height: $logo-height;
  max-height: 60px;
  // max-width: 200px;
  max-width: 150px;
}

.pointer {
  cursor: pointer;
}

.hide {
  display: none;
}

.uppercase {
  text-transform: uppercase;
}

.underline{
  text-decoration: underline;
}

.medium{
  font-weight: 500;
}
.bold {
  font-weight: 600;
}

.no_line_break {
  white-space: nowrap;
}

.regular{
  font-weight: 400;
}
.fs10{
  font-size: 10px;
}
.fs11{
  font-size: 11px;
}
.fs12{
  font-size: 12px !important;
}
.fs14{
  font-size: 14px !important;
}
.fs16{
  font-size: 16px;
}
.fs18{
  font-size: 18px;
}
.fs20 {
  font-size: 20px;
}
.fs22{
  font-size: 22px;
}
.fs24{
  font-size: 24px;
}
.fs28{
  font-size: 28px;
}
.fs30{
  font-size: 30px;
}

.h20 {
  height: 20px!important;;
}
.h25 {
  height: 25px!important;;
}
.h30 {
  height: 30px!important;;
}
.h40 {
  height: 40px!important;
}
.h50 {
  height: 50px!important;;
}
.h60 {
  height: 60px!important;;
}
.h70 {
  height: 70px!important;;
}
.h100 {
  height: 100px!important;;
}
.he-100 {
  height: 100% !important;
}

.w20 {
  width: 20px!important;
}

.w25 {
  width: 25px!important;
}

.w30p {
  width: 30% !important;
}

.w50 {
  width: 50px!important;
}

.z-index-1 {
  z-index: 1 !important;
}
.z-index-m1 {
  z-index: -1 !important;
}

.c-white {
  color: #FFF !important;
}

.c-black {
  color: #000 !important;
}

.c-main {
  color: $main-color !important;
}
.c-grey {
  color: $dark-grey !important;
}
.c-danger {
  color: $danger-color;
}
.c-warning {
  color: #ffc107;
}

.c-link {
  color: $link-color !important;
}

.c-header-text {
  color : $header-text-color
}
.c-header-highlight-text {
  color : $header-highlight-color
}

.bg-main-light {
  background-color: rgba($main-color-rgb,0.2) !important;
}
.bg-warning-light {
  background-color: rgba(#ffc107,0.2);
}

.bg-danger-light {
  background-color: rgba($danger-color, 0.2)!important;
}
.bg-transparent {
  background-color: rgba(0,0,0,0);
}

.break-space {
  white-space: break-spaces;
}

.no-border{
  border: none
}

.bradius-5 {
  border-radius: 5px;
}

.border-main {
  border: 1px solid $main-color !important;
}
.object-fit-cover {
  object-fit: cover;
}

.react-datepicker__portal .react-datepicker__day, .react-datepicker__portal .react-datepicker__day-name, .react-datepicker__portal .react-datepicker__time-name{
  width: 2rem;
  line-height: 2rem;
}

.loader {
  .loader-inner {
    &.main-color {
      &.ball-pulse {
        display: flex;
        justify-content: center;
        & > div {
          background-color: $main-color;
        }
      }

      &.ball-clip-rotate-multiple > div {
        border-color: $main-color transparent;
      }
    }
    &.light-gray-color {
      &.ball-clip-rotate-multiple > div {
        border-color: $light-gray transparent;
      }
      &.ball-pulse {
        display: flex;
        justify-content: center;
        & > div{
          background-color: $light-gray;
        }
      }
    }
  }
}

.logo-src {
  position: relative;
  .loader_dots {
    position: absolute;
    right: -50px;
    top: 50%;
  }
}

.app-sidebar .loader_dots {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
  opacity: 0;
}
.app-header .loader_dots {
  opacity: 1;
}

.closed-sidebar {
  &.closed-sidebar-mobile .app-sidebar .loader_dots {
    opacity: 0;
  }
  .app-sidebar .loader_dots {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 50px;
    opacity: 1;
  }
  //&.sidebar-mobile-open .app-header .loader_dots {
  //  opacity: 0;
  //}
}

.main-color {
  color: $main-color;
}

.btn-outline-main {
  background-color: transparent !important;
  color: $main-color !important;
  border: 1px solid $main-color !important;
}

.btn-outline-main:hover {
  background-color: $main-color !important;
  color: #fff !important;
}

.bg-main {
  background-color: $main-color !important;
}
.bg-white {
  background-color: #FFFFFF !important;
}
.cta-btn {
  display: block;
  height: 50px;
  text-align: center;
  color: #fff;
  background-color: $main-color !important;
  border: none !important;
}

.bg-container {
  background-color: #f1f4f6 !important;
}
.bg-light-grey {
  background-color: $light-gray !important;
}

.btn {
  &:focus {
    box-shadow: 0 0 0 0.25rem rgba($main-color-rgb,0.5);
  }
}

.vertical-time-icons:before{
  top: 20px !important;
  height: calc(100% - 20px) !important;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
  .pointer {
    cursor: auto;
  }
}

.cusror-progress {
  cursor: progress!important;
}

.Burger {
  padding: 15px 0 !important;
}

#burger-menu span{
  display: block;
  padding-left: 35px;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  background: #cdcdcd;
  border-radius: 3px;
}
#collapseBurgerMenu {
  //position: absolute;
  left: 0;
  top: 70px;
  width: 100%;
  border-bottom: 1px solid #dee2e6!important;
}

.menu-drop-down{
  border-top: solid 3px $main-color !important;
  border-radius: 0 !important;
  & a.dropdown-item:hover{
    background-color: unset !important;
  }
  & a.dropdown-item:active{
    background-color: unset !important;
    color: $main-color !important;
  }
}

#footer{
  & img.payment-logo{
    height: 40px;
    width: 70px;
    &.visa{
      object-fit: contain;
    }
    &.mastercard{
      object-fit: cover;
    }
    &.amex{
      object-fit: cover;
    }
    &.gpay{
      object-fit: contain;
    }

  }
}
//@media (max-width: 768px) {
//  header#header {
//    position: fixed;
//    top: 0;
//  }
//  body {
//    padding-top: 70px;
//  }
//}



.contact-banner {
  height: 50px;
}

@media (max-width: 768px) {
  //header#header  {
  //  min-height: 65px;
  //}

  .contact-banner {
    padding-top: 20px;
    padding-bottom: 20px;
    height: auto;

    .content {
      align-items: start;
      .item {
        padding: 4px 0;
      }
    }
  }
  .image-banner{
    .subtitle{
      font-size: 16px !important;
    }
  }
  
}