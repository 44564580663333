//@import "assets/sass/bootstrap/scss/bootstrap";
@import "base/util";
@import "base/global";
//@import "components/rc-tabs";
// @import "components/day-picker";

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
    height: 4px;
}

::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: $main-color;
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}