@import "../utils/mixins";
//@import "../utils/font-size";
@import "../utils/variables";

//
//@include font-face(NiveauGrotesk, "../../fonts/FontNiveauGrotesk/NiveauGroteskExtraLight", 200 , normal,  woff2 woff ttf);
//@include font-face(NiveauGrotesk, "../../fonts/FontNiveauGrotesk/NiveauGroteskExtraLight-Italic", 200 , italic,  woff2 woff ttf);
//@include font-face(NiveauGrotesk, "../../fonts/FontNiveauGrotesk/NiveauGroteskLight", 300 , normal, eot woff2 woff ttf);
//@include font-face(NiveauGrotesk, "../../fonts/FontNiveauGrotesk/NiveauGroteskLight-Italic", 300 , italic,  woff2 woff ttf);
//@include font-face(NiveauGrotesk, "../../fonts/FontNiveauGrotesk/NiveauGroteskRegular", normal , normal,  woff2 woff ttf);
//@include font-face(NiveauGrotesk, "../../fonts/FontNiveauGrotesk/NiveauGroteskRegular-Italic", normal , italic, eot woff2 woff ttf);
//@include font-face(NiveauGrotesk, "../../fonts/FontNiveauGrotesk/NiveauGroteskMedium", 500 , normal,  woff2 woff ttf);
//@include font-face(NiveauGrotesk, "../../fonts/FontNiveauGrotesk/NiveauGroteskMedium-Italic", 500 , italic,  woff2 woff ttf);
//@include font-face(NiveauGrotesk, "../../fonts/FontNiveauGrotesk/NiveauGroteskBold", bold , normal,  woff2 woff ttf);
//@include font-face(NiveauGrotesk, "../../fonts/FontNiveauGrotesk/NiveauGroteskBold-Italic", bold , italic,  woff2 woff ttf);
//@include font-face(NiveauGrotesk, "../../fonts/FontNiveauGrotesk/NiveauGroteskBlack-Italic", 900 , italic,  woff2 woff ttf);
//@include font-face(NiveauGrotesk, "../../fonts/FontNiveauGrotesk/NiveauGroteskBlack", 900 , normal,  woff2 woff ttf);

@include font-face(Roboto, "../../fonts/Roboto/Roboto-Black", 900 , normal, ttf);
@include font-face(Roboto, "../../fonts/Roboto/Roboto-Bold", 700 , normal, ttf);
@include font-face(Roboto, "../../fonts/Roboto/Roboto-Medium", 500 , normal, ttf);
@include font-face(Roboto, "../../fonts/Roboto/Roboto-Regular", 400 , normal, ttf);
@include font-face(Roboto, "../../fonts/Roboto/Roboto-Light", 300 , normal, ttf);

.btn-primary {
  background-color: $main-color !important;
  border-color: $main-color !important;
}